import React, { CSSProperties } from 'react';
import './App.css';
import { State } from './App';
import { Colors } from './Colors';
import { Card, FormControlLabel, Checkbox, withStyles } from '@material-ui/core';
import { Util } from './Util';
import { CategorySetting } from './CategorySetting';

interface CategorySelectorProps 
{
    categorySetting: CategorySetting
}

export class CategorySelector extends React.Component<CategorySelectorProps>
{
    checkBoxStyles = () => ({
        root: {
            color: Colors.midGrey,
            '&$checked': {
                color: Colors.white
            },
            '&:hover': {
                backgroundColor: Colors.transparent
            }
        },
        checked: {},
    })

    CustomCheckbox = withStyles(this.checkBoxStyles)(Checkbox);

    render() 
    {
        let isMobile = Util.isMobileDevice();
        let checkboxContainerStyle: CSSProperties =
        {
            background: this.props.categorySetting.enabled ? Colors.getCategoryColor(this.props.categorySetting.category) : Colors.background,
            color: this.props.categorySetting.enabled ? Colors.white : Colors.midGrey,
            display: "inline-block",
            padding: isMobile ? "0 0 0 12px" : "4px 8px 4px 16px",
            margin: isMobile ? "8px 4px 0 4px" : "0 0 0 16px",
            borderRadius: "1000px",
            userSelect: "none",
            cursor: "pointer",
            verticalAlign: "middle"
        };

        return (
            <Card style={checkboxContainerStyle} onClick={(e) => this.stateChanged(e)}>
                <FormControlLabel
                    control={<this.CustomCheckbox checked={this.props.categorySetting.enabled} onChange={() => {}} name="checkbox" />}
                    label={this.props.categorySetting.category}
                />
            </Card>
        )
    }

    stateChanged(e : React.MouseEvent) 
    {
        e.preventDefault();
        State.toggleCategory(this.props.categorySetting.category);
    }
}