import React, { CSSProperties } from 'react';
import './App.css';
import { Colors } from './Colors';
import { Styles } from './Styles';
import { Card, Button } from '@material-ui/core';
import { Util } from './Util';
import { State } from './App';

export class AboutMeProfileCard extends React.Component
{
    profilePartOne = `I'm a Software Engineer and Musician based in Manchester, UK.
    I am passionate about the creative process because I love making great things.
    Whether I'm writing code or music, I enjoy streamlining my workflow to produce the best possible results.`;
    profilePartTwo = `Currently I work as a mobile app developer based in Manchester. 
    I have worked at Aviva plc, Engage Solutions Group and Shout Platform Ltd where I honed my skills in UI Design, TypeScript, C# and Xamarin.
    My interest in music also lead me to acheiving a first class degree in Music Technology BA (Hons) at the University of Huddersfield.`;

    render()
    {
        let isMobile = Util.isMobileDevice();

        if(isMobile) return this.renderMobile();

        return this.renderDesktop();
    }

    renderDesktop()
    {
        let profileCardStyle : CSSProperties = 
        {
            background: Colors.fill,
            color: Colors.white,
            maxWidth: "720px",
            height: "400px",
            margin: "16px",
            display: "flex",
            flexDirection: "row"
        };

        let imageStyle : CSSProperties = 
        {
            height: "100%"
        };

        let cardHeadingStyle = Styles.cardHeadingStyle;
        cardHeadingStyle.display = "inline-block";

        let textContainerStyle : CSSProperties = 
        {
            position: "relative",
            padding: "16px",
            flex: 1
        };

        let profileSecondParagraphStyle = Styles.textStyle;
        profileSecondParagraphStyle.marginTop = "12px";

        let buttonStyle : CSSProperties = 
        {
            color: Colors.white,
            margin: "8px",
            float: "right"
        };

        let buttonContainerStyle : CSSProperties = 
        {
            width: "100%",
            position: "absolute",
            bottom: 0,
            right: 0
        };

        return (
            <Card style={profileCardStyle}>
                <img src="https://tomdawes.co.uk/mainsite/media/tom.jpg" style={imageStyle} />
                <div style={textContainerStyle}>
                    <h1 style={cardHeadingStyle}>Tom</h1>
                    <p style={Styles.textStyle}>
                        {this.profilePartOne}
                    </p>
                    <p style={profileSecondParagraphStyle}>
                        {this.profilePartTwo}
                    </p>
                    <div style={buttonContainerStyle}>
                        <Button style={buttonStyle} onClick={() => State.scrollTo("contact")}>Get in touch</Button>
                    </div>
                </div>
            </Card>
        )
    }

    renderMobile()
    {
        let profileCardStyle : CSSProperties = 
        {
            background: Colors.fill,
            color: Colors.white,
            width: "100%",
            maxWidth: "720px",
            margin: "8px",
            display: "flex",
            flexDirection: "column"
        };

        let imageStyle : CSSProperties = 
        {
            width: "100%"
        };

        let cardHeadingStyle = Styles.cardHeadingStyle;
        cardHeadingStyle.display = "inline-block";

        let textContainerStyle : CSSProperties = 
        {
            position: "relative",
            padding: "16px 16px 0 16px",
            flex: 1
        };

        let profileSecondParagraphStyle = Styles.textStyle;
        profileSecondParagraphStyle.marginTop = "12px";

        let buttonContainerStyle : CSSProperties = 
        {
            width: "100%",
            textAlign: "right"
        };

        let buttonStyle : CSSProperties = 
        {
            color: Colors.white,
            margin: "8px"
        };

        return (
            <Card style={profileCardStyle}>
                <img src="https://tomdawes.co.uk/mainsite/media/tom.jpg" style={imageStyle} />
                <div style={textContainerStyle}>
                    <h1 style={cardHeadingStyle}>Tom</h1>
                    <p style={Styles.textStyle}>
                        {this.profilePartOne}
                    </p>
                    <p style={profileSecondParagraphStyle}>
                        {this.profilePartTwo}
                    </p>
                </div>
                <div style={buttonContainerStyle}>
                    <Button style={buttonStyle} onClick={() => State.scrollTo("contact")}>Get in touch</Button>
                </div>
            </Card>
        )
    }
}