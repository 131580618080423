import React, { CSSProperties } from 'react';
import './App.css';
import { Colors } from './Colors';
import { Styles } from './Styles';
import { Card } from '@material-ui/core';

interface SkillHolderProps
{
    name : string,
    color? : string
}

interface SkillHolderState
{
    hover : boolean,
    color : string
}

export class SkillHolder extends React.Component<SkillHolderProps, SkillHolderState>
{
    state = { hover: false, color: Colors.transparent };

    componentDidMount()
    {
        //choose random sunset color
        this.setState({ color: Colors.sunset[Math.floor(Math.random() * Colors.sunset.length)] });
    }

    render()
    {
        let borderColor = this.state.hover ? Colors.white : Colors.lightGrey;

        let containerStyle : CSSProperties = 
        {
            background: this.state.hover ? this.getColor() : Colors.fill,
            border: "1px solid " + borderColor,
            boxSizing: "border-box",
            display: "inline-block",
            marginRight: "8px",
            //position: "relative",
            fontFamily: Styles.textFont,
            marginTop: "8px",
            userSelect: "none"
        };

        let textStyle : CSSProperties = 
        {
            color: Colors.white,
            padding: "4px 16px",
            display: "inline-block",
            zIndex: 1,
            fontFamily: Styles.textFont
        }

        return (
            <Card style={containerStyle} elevation={this.state.hover ? 5 : 1} onMouseEnter={() => this.hoverStart()} onMouseLeave={() => this.hoverEnd()}>
                <div style={textStyle}>
                    {this.props.name}
                </div>
            </Card>
        )
    }

    hoverStart()
    {
        this.setState({ hover: true });
    }

    hoverEnd()
    {
        this.setState({ hover: false });
    }

    getColor()
    {
        return this.props.color ?? this.state.color;
    }
}