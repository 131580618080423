export class Coms
{
    static get masterAddress()
    {
        return "https://tomdawes.co.uk/";
    }

    static get baseAddress()
    {
        return "https://tomdawes.co.uk/mainsite/";
    }

    static url(fileName : string)
    {
        return this.baseAddress + fileName;
    }

    static mediaLink(link : string)
    {
        return this.baseAddress + "media/" + link;
    }

    static projectUrl(link : string)
    {
        if(link.startsWith("http"))
            return link;
        
        if(link.startsWith("?"))
            return "#projectFocus";

        return this.masterAddress + link;
    }

    static projectImageUrl(fileName : string)
    {
        return this.baseAddress + "thumbnails/" + fileName;
    }

    static projectsRequest()
    {
        let queryFile = this.url("getProjects.php");
    
        let request = new XMLHttpRequest();
        request.open('POST', queryFile, true);
        request.responseType = 'text';

        return request;
    }

    static contactRequest()
    {
        let requestFile = this.url("contact.php");
    
        let request = new XMLHttpRequest();
        request.open('POST', requestFile, true);
        request.responseType = 'text';

        return request;
    }
}