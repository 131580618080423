import React, { createRef, CSSProperties } from 'react';
import './App.css';
import { Project } from './Project';
import { ProjectsGrid } from './ProjectsGrid';
import { ProjectsContainer } from './ProjectsContainer';
import { IntroductionContainer } from './IntroductionContainer';
import { CategorySetting } from './CategorySetting';
import { AboutMe } from './AboutMe';
import { ContactMe } from './ContactMe';
import { Coms } from './Coms';
import { ProjectFocusView } from './ProjectFocusView';
import { Util } from './Util';

export class State
{
    static app : App;

    static loadProjects()
    {
        let request = Coms.projectsRequest();
        request.onload = () =>
        {
            try
            {
                let projects = JSON.parse(request.response) as Project[];
                State.setProjects(projects);
            }
            catch
            {
                State.setProjects([]);
            }
        };

        request.send();

        this.app?.setState({ projectsLoadState: "loading" });
    }

    static setProjects(projects : Project[])
    {
        let projectsLoadState : "success" | "error" | "loading" = "success";
        if(projects.length === 0)
            projectsLoadState = "error";

        //get unique category strings from project list
        let projectCategories : string[] = [];
        projects.forEach(project => {
            if(!projectCategories.includes(project.category))
                projectCategories.push(project.category);
        });

        //make string array into CategorySetting array
        let projectCategorySettings : CategorySetting[] = [];
        projectCategories.forEach(category => {
            projectCategorySettings.push(new CategorySetting(category));
        });

        this.app.setState({ projectsLoadState: projectsLoadState, projects: projects, categorySettings: projectCategorySettings });
    }

    static setFocusProject(id : string)
    {
        this.app.setState({ focusProjectLink: id });
    }

    static toggleCategory(category : string, enabled? : boolean)
    {
        //find the category
        let categorySettings = this.app.state.categorySettings as CategorySetting[];
        categorySettings.forEach(categorySetting => 
        {
            if(categorySetting.category === category)
                categorySetting.enabled = enabled == null ? !categorySetting.enabled : enabled;
        });

        this.app.setState({ categorySettings: categorySettings });
    }

    static scrollTo(section : string)
    {
        this.app.scrollDown(section);
    }
}

interface AppProps
{

}

interface AppState
{
    projectsLoadState : "loading" | "success" | "error",
    projects : Project[],
    categorySettings : CategorySetting[],
    focusProjectLink : string
}

class App extends React.Component<AppProps, AppState>
{
    state : AppState = { projectsLoadState: "loading", projects: [], categorySettings: [], focusProjectLink: "" };

    projectsSection = createRef<HTMLDivElement>();
    contactSection = createRef<HTMLDivElement>();

    componentDidMount()
    {
        State.app = this;
    }

    render()
    {
        let outerDivStyle : CSSProperties = 
        {
            width: "100%",
            overflowX: "hidden"
        }

        return (
            <div style={outerDivStyle}>
                <IntroductionContainer />
                <div ref={this.projectsSection}>
                    <a id="projects"/>
                    <ProjectsContainer projectsLoadState={this.state.projectsLoadState} projects={this.state.projects} categorySettings={this.state.categorySettings}/>
                </div>
                <a id="projectFocus"/>
                <ProjectFocusView project={this.getProjectFromLink()}/>
                <AboutMe />
                <div ref={this.contactSection}>
                    <ContactMe />
                </div>
            </div>
        )
    }

    scrollDown(section : string)
    {
        switch(section)
        {
            case "projects":
                this.projectsSection.current?.scrollIntoView({ behavior: "smooth" });
                break;
            case "contact":
                this.contactSection.current?.scrollIntoView({ behavior: "smooth" });
                break;
        }
    }

    getProjectFromLink()
    {
        for (let i = 0; i < this.state.projects.length; i++) 
        {
            if(this.state.projects[i].link === this.state.focusProjectLink)
                return Util.copy(this.state.projects[i]);
        }
        
        return null;
    }
}

export default App;
