import React, { CSSProperties } from 'react';
import './App.css';
import { Project } from './Project';
import { Coms } from './Coms';
import { State } from './App';
import { Colors } from './Colors';
import { AppBar, Card } from '@material-ui/core';
import { Util } from './Util';
import { CategorySelector } from './CategorySelector';
import { CategorySetting } from './CategorySetting';
import { Styles } from './Styles';

interface SectionHeadingProps
{
    title : string
}

export class SectionHeading extends React.Component<SectionHeadingProps>
{
    render()
    {
        let isMobile = Util.isMobileDevice();
        let padding = isMobile ? "16px 12px" : "16px 32px";

        let containerStyle : CSSProperties = 
        {
            background: Colors.fill,
            padding: padding,
            position: "relative",
            //marginBottom: "8px"
        };

        let headingStyle : CSSProperties = 
        {
            color: Colors.white,
            verticalAlign: "middle",
            padding: 0,
            margin: 0,
            marginRight: "16px",
            display: "inline-block",
        };

        let innerContainerStyle : CSSProperties = 
        {
            display: "table-cell",
            verticalAlign: "middle"
        };

        return (
            <AppBar style={containerStyle} elevation={1}>
                <div style={innerContainerStyle}>
                    <h1 style={headingStyle}>{this.props.title}</h1>
                </div>
            </AppBar>
        )
    }
}