import { CategorySetting } from "./CategorySetting";

export class Util
{
    static copy(object : any)
    {
        return Object.assign({}, object);
    }

    static isMobileDevice()
    {
        return window.matchMedia("only screen and (max-width: 760px)").matches;
    }

    static cssCalcSubtract(bigValue : string, smallValue : string)
    {
        return "calc(" + bigValue + " - " + smallValue + ")";
    }

    static cssPixelsToString(pixels : number)
    {
        return pixels.toString() + "px";
    }

    static categoryIsEnabled(categorySettings : CategorySetting[], category : string)
    {
        let enabled = false;
        categorySettings.forEach(categorySetting => 
        {
            if(categorySetting.category === category)
                enabled = categorySetting.enabled;
        });

        return enabled;
    }

    static validateEmail(email : string) 
    {
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    }
}