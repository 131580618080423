import React, { CSSProperties } from 'react';
import './App.css';
import { Colors } from './Colors';
import { Styles } from './Styles';
import { State } from './App';

interface ViewWorkButtonProps
{
}

interface ViewWorkButtonState
{
    buttonState : boolean
}

export class ViewWorkButton extends React.Component<ViewWorkButtonProps, ViewWorkButtonState>
{
    state = { buttonState: false }

    render()
    {
        let buttonInactiveStyle : CSSProperties = 
        {
            backgroundColor: "transparent",
            color: Colors.white,
            borderStyle: "solid",
            borderColor: Colors.white,
            borderWidth: 1,
            borderRadius: 4,
            padding: "1.75vh 3.5vh",
            fontSize: "2vh",
            fontFamily: Styles.headingFont,
            fontWeight: "bold",
            margin: "4vh 0",
            cursor: "pointer",
            outline: "none"
        };

        let buttonActiveStyle = Object.assign({}, buttonInactiveStyle);
        buttonActiveStyle.backgroundColor = Colors.white;
        buttonActiveStyle.color = Colors.fill;

        return (
            <button style={this.state.buttonState ? buttonActiveStyle : buttonInactiveStyle} onClick={() => State.scrollTo("projects")} onMouseEnter={() => this.buttonHoverStart()} onMouseLeave={() => this.buttonHoverEnd()}>View my work</button>
        )
    }

    buttonHoverStart()
    {
        this.setState({ buttonState: true });
    }

    buttonHoverEnd()
    {
        this.setState({ buttonState: false });
    }
}