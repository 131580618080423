import React, { CSSProperties } from 'react';
import './App.css';
import { Colors } from './Colors';
import { Styles } from './Styles';
import { ViewWorkButton } from './ViewWorkButton';
import { AnimatedBackground } from './AnimatedBackground';

export class IntroductionContainer extends React.Component
{
    render()
    {
        let containerStyle : CSSProperties = 
        {
            padding: "16px",
            textAlign: "center",
            verticalAlign: "middle",
            width: "100vw",
            height: "100vh",
            boxSizing: "border-box",
            display: "table-cell"
        };

        let h1Style : CSSProperties = 
        {
            color: Colors.white,
            padding: 0,
            margin: 0,
            textAlign: "center",
            fontSize: "6vh",//"56px",
            fontWeight: "bold",
            fontFamily: Styles.headingFont,
            textSizeAdjust: "none"
        };

        let h2Style : CSSProperties = 
        {
            color: Colors.white,
            padding: 0,
            margin: 0,
            marginTop: "8px",
            textAlign: "center",
            fontSize: "3vh",//"32px",
            fontWeight: "normal",
            fontFamily: Styles.headingFont
        };
        
        return (
            <div style={containerStyle}>
                <AnimatedBackground/>
                <h1 style={h1Style}>Hi, I'm Tom Dawes.</h1>
                <h2 style={h2Style}>Software Engineer</h2>
                <ViewWorkButton/>
            </div>
        )
    }
}