import React, { CSSProperties } from 'react';
import './App.css';
import { Colors } from './Colors';
import { Styles } from './Styles';
import { ViewWorkButton } from './ViewWorkButton';
import { SectionHeading } from './SectionHeading';
import { Card, Grid, Button } from '@material-ui/core';
import { SkillHolder } from './SkillHolder';
import { Util } from './Util';
import { State } from './App';
import { AboutMeProfileCard } from './AboutMeProfileCard';

export class AboutMe extends React.Component
{
    render()
    {
        let isMobile = Util.isMobileDevice();

        let containerStyle : CSSProperties = 
        {
            width: "100%"
        };

        let gridStyle : CSSProperties = 
        {
            padding: isMobile ? "8px" : "16px",
            width: "100%",
            boxSizing: "border-box"
        };

        let skillsCardStyle : CSSProperties = 
        {
            background: Colors.fill,
            color: Colors.white,
            margin: isMobile ? "8px" : "16px",
            display: "inline-block",
            width: "280px",
            padding: "16px"
        };

        let skillsContainerStyle : CSSProperties = 
        {
            display: "block",
            textAlign: "center",
            marginTop: 8
        };

        let cardHeadingStyle = Styles.cardHeadingStyle;
        cardHeadingStyle.display = "inline-block";

        return (
            <div style={containerStyle}>
                <SectionHeading title={"About Me"}/>
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="stretch" style={gridStyle}>
                    <AboutMeProfileCard />
                    <Card style={skillsCardStyle}>
                        <h1 style={cardHeadingStyle}>Programming Skills</h1>
                        <div style={skillsContainerStyle}>
                            <SkillHolder name="C#"/>
                            <SkillHolder name=".NET"/>
                            <SkillHolder name="MAUI"/>
                            <SkillHolder name="Xamarin"/>
                            <SkillHolder name="MvvmCross"/>
                            <SkillHolder name="TypeScript" color={Colors.sunset[0]}/>
                            <SkillHolder name="JavaScript" color={Colors.sunset[2]}/>
                            <SkillHolder name="React"/>
                            <SkillHolder name="HTML"/>
                            <SkillHolder name="CSS"/>
                            <SkillHolder name="PHP"/>
                            <SkillHolder name="C++"/>
                            <SkillHolder name="Unity"/>
                            <SkillHolder name="UI Design"/>
                            <SkillHolder name="Unit Testing"/>
                            <SkillHolder name="Automation"/>
                            <SkillHolder name="Algorithms"/>
                        </div>
                    </Card>
                    {/*
                    <Card style={skillsCardStyle}>
                        <h1 style={cardHeadingStyle}>Audio Skills</h1>
                        <div style={skillsContainerStyle}>
                            <SkillHolder name="Logic Pro"/>
                            <SkillHolder name="FL Studio"/>
                            <SkillHolder name="Pro Tools"/>
                            <SkillHolder name="Max MSP"/>
                            <SkillHolder name="Mixing"/>
                            <SkillHolder name="Composition"/>
                            <SkillHolder name="Sound Design"/>
                            <SkillHolder name="Arrangement"/>
                        </div>
                    </Card>*/}
                </Grid>
            </div>
        )
    }
}