import React, { CSSProperties, createRef } from 'react';
import './App.css';
import { Project } from './Project';
import { Coms } from './Coms';
import { State } from './App';
import { Colors } from './Colors';
import { AppBar, Card, colors } from '@material-ui/core';
import { Util } from './Util';
import { CategorySelector } from './CategorySelector';
import { CategorySetting } from './CategorySetting';

interface MobileCategoriesContainerProps
{
    categorySettings: CategorySetting[]
}

export class MobileCategoriesContainer extends React.Component<MobileCategoriesContainerProps>
{
    render()
    {
        let containerStyle : CSSProperties = 
        {
            padding: "8px 8px 0 8px"
        };

        return (
            <div style={containerStyle}>
                {this.props.categorySettings.map((category, key) => 
                    <CategorySelector categorySetting={category} key={key}/>)}
            </div>
        )
    }
}