import { Colors } from "./Colors";

export class Styles
{
    static get textStyle() : React.CSSProperties
    {
        return {
            color: Colors.lightGrey,
            fontSize: 14,
            fontFamily: Styles.textFont,
            margin: 0
        }; 
    };

    static get headingFont()
    {
        return "Montserrat, Verdana, sans-serif"
    };

    static get textFont()
    {
        return "Roboto, sans-serif"
    };

    static get cardHeadingStyle() : React.CSSProperties
    {
        return {
            padding: 0,
            margin: "0 0 8px 0",
            fontSize: "28px"
        };
    }

    static get inlineBlock() : React.CSSProperties
    {
        return {
            display: "inline-block"
        };
    }
}