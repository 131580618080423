import React, { createRef, CSSProperties } from 'react';
import { Colors } from './Colors';

type Point = { position: Position, xSpeed: number, ySpeed: number, color: string };
type Position = [number, number];

export class AnimatedBackground extends React.Component
{
    points : Point[] = [];
    mousePosition : Position = [0, 0];
    settings = { simulationSpeed: 0.01, pointSpeed: 1, pointMass: 1000, numberOfPoints: 50, shockwaveSize: 3, shockwaveMargin: 6, drag: 0.025, gravity: 15, maxForce: 2000, minLineDistance: 150 };

    canvas = createRef<HTMLCanvasElement>();
    context : CanvasRenderingContext2D | null = null;
    drawInterval : any;

    render()
    {
        let canvasStyle : CSSProperties = 
        {
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100vh",
            zIndex: -1
        };

        return <canvas ref={this.canvas} width={this.getTrueWidth()} height={this.getTrueHeight()} style={canvasStyle}/>
    }

    componentDidMount() {
        //get mouse movement updates
        window.onmousemove = (e : MouseEvent) => this.mouseMove(e);

        this.context = this.canvas.current!.getContext("2d")!;

        console.log("Context: " + this.context);

        // Create a line and a grid, and convert them to `Noise` points
        //let gd = Create.gridPts(this.space.innerBound, 20, 20);
        //this.noiseGrid = Create.noisePts(gd, 0.05, 0.1, 20, 20);

        const width = this.getVirtualWidth();
        const height = this.getVirtualHeight();
        const area = width * height;
        const sizeCoef = area * 0.000001;

        //fewer points and slower speed for small devices
        let numberOfPoints = Math.floor(this.settings.numberOfPoints * sizeCoef);
        this.settings.simulationSpeed *= sizeCoef;

        let pointSpeed = this.settings.pointSpeed;
        this.points = [];
        for (let i = 0; i < numberOfPoints; i++) 
        {
            let newX = width * Math.random();
            let newY = height * Math.random();

            let xSpeed = pointSpeed * (Math.random() * 2 - 1);
            let ySpeed = pointSpeed * (Math.random() * 2 - 1);

            let newColor = Colors.sunset[Math.floor(Math.random() * Colors.sunset.length)];

            let newPoint : Point = { position: [newX, newY], xSpeed: xSpeed, ySpeed: ySpeed, color: newColor };
            this.points.push(newPoint);
        }

        //this.space.background = Colors.background;

        this.drawInterval = setInterval(() => this.draw(), 10);
    }

    componentWillUnmount(): void 
    {
        clearInterval(this.drawInterval);
    }

    updateDotPositions()
    {
        const width = this.getVirtualWidth();
        const height = this.getVirtualHeight();

        //move dots
        this.points.forEach(point => 
        {
            this.moveTowardsMouse(point);

            this.applyDrag(point);

            this.applyShockwave(point);

            //move point
            point.position[0] += point.xSpeed * this.settings.simulationSpeed;
            point.position[1] += point.ySpeed * this.settings.simulationSpeed;

            //wrap point at edges
            let wrapMargin = 32;

            if(point.position[0] < -wrapMargin)
                point.position[0] = width;
            else if(point.position[0] > width + wrapMargin)
                point.position[0] = 0;

            if(point.position[1] < -wrapMargin)
                point.position[1] = height;
            else if(point.position[1] > height + wrapMargin)
                point.position[1] = 0;
        });
    }

    draw()
    {
        if (!this.points) return;

        if (!this.context) return;

        this.updateDotPositions();

        const width = this.getTrueWidth();
        const height = this.getTrueHeight();

        //grab a new context if needed
        if (!this.context || !this.context.clearRect)
            this.context = this.canvas.current!.getContext("2d")!;

        //clear canvas
        this.context.clearRect(0, 0, width, height);
        this.context.fillStyle = Colors.background;
        this.context.fillRect(0, 0, width, height);

        //draw lines
        let minimumDistance = this.settings.minLineDistance;

        //compares each point to every other point (but not to itself)
        for (let i = 0; i < this.points.length; i++) 
        {
            const pointA = this.points[i];
            
            for (let j = 0; j < this.points.length; j++)
            {
                if(i <= j) continue; //do not compare point to itself, also this check prevents pairs from being compared twice

                const pointB = this.points[j];

                let distanceBetweenPoints = this.calculateDistance(pointA.position, pointB.position);
                let lineOpacity = 1 - (distanceBetweenPoints / minimumDistance);
                lineOpacity = this.clamp(lineOpacity, 0, 1);

                if(distanceBetweenPoints < minimumDistance)
                {
                    this.drawLine(pointA.position, pointB.position, pointA.color, lineOpacity);
                }
            }
        }

        //draw dots
        this.points.forEach(point => 
        {
            this.drawCircle(point.position, 2 * window.devicePixelRatio, point.color);
        });
    }

    drawLine(startPosition : Position, endPosition : Position, lineColor : string, lineWidth : number)
    {
        const trueStartPosition = this.toTruePosition(startPosition);
        const trueEndPosition = this.toTruePosition(endPosition);

        this.context!.beginPath();
        this.context!.moveTo(trueStartPosition[0], trueStartPosition[1]);
        this.context!.lineTo(trueEndPosition[0], trueEndPosition[1]);

        this.context!.strokeStyle = lineColor;
        this.context!.lineWidth = lineWidth * window.devicePixelRatio;
        this.context!.stroke();
    }

    drawCircle(centerPosition : Position, size : number, color : string)
    {
        const trueCenterPosition = this.toTruePosition(centerPosition);

        this.context!.fillStyle = color;
        this.context!.beginPath();
        this.context!.arc(trueCenterPosition[0], trueCenterPosition[1], size, 0, 2 * Math.PI);
        this.context!.fill();
    }

    toTruePosition(position : Position)
    {
        return [position[0] * window.devicePixelRatio, position[1] * window.devicePixelRatio];
    }

    getVirtualWidth()
    {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    getVirtualHeight()
    {
        return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    }

    getTrueWidth()
    {
        return this.getVirtualWidth() * window.devicePixelRatio;
    }

    getTrueHeight()
    {
        return this.getVirtualHeight() * window.devicePixelRatio;
    }

    moveTowardsMouse(point : Point)
    {
        //F = G(m1 * m2) / (r*r)

        let pointMass = this.settings.pointMass;
        let G = this.settings.gravity;
        let mouseDistance = this.calculateDistance(point.position, this.mousePosition);
        let force = (G * pointMass * pointMass) / (mouseDistance * mouseDistance);

        //limit force
        let maxForce = this.settings.maxForce;
        if(force > maxForce)
            force = maxForce;

        //F = MA
        //F / M = A

        let acceleration = force / pointMass; //acceleration in direction of mouse

        let angle = this.calculateAngle(this.mousePosition, point.position); //direction of mouse

        let accelX = acceleration * Math.cos(angle);
        let accelY = acceleration * Math.sin(angle);

        point.xSpeed += accelX;
        point.ySpeed += accelY;
    }

    applyDrag(point : Point)
    {
        let dragAmount = this.settings.drag;
        let dragCoef = 1 - dragAmount;
        point.xSpeed *= dragCoef;
        point.ySpeed *= dragCoef;
    }

    applyShockwave(point : Point)
    {
        let pointSpeed = Math.sqrt(point.xSpeed * point.xSpeed + point.ySpeed * point.ySpeed);
        let mouseDistance = this.calculateDistance(point.position, this.mousePosition);
        let shockwaveMargin = this.settings.shockwaveMargin;
        let shockwaveSize = this.settings.shockwaveSize;
        if(mouseDistance < shockwaveMargin)
        {
            let randomAngle = 2 * Math.PI * Math.random();
            point.xSpeed = pointSpeed * shockwaveSize * Math.cos(randomAngle);
            point.ySpeed = pointSpeed * shockwaveSize * Math.sin(randomAngle);
        }
    }

    clamp(number : number, min : number, max : number)
    {
        return Math.min(Math.max(number, min), max);
    }

    mouseMove(e : any)
    {
        this.mousePosition = [e.clientX, e.clientY + window.scrollY];
    }

    //pythagoras 2d distance function
    calculateDistance(positionA : Position, positionB : Position)
    {
        let xDistance = positionA[0] - positionB[0];
        let yDistance = positionA[1] - positionB[1];

        return Math.sqrt(xDistance * xDistance + yDistance * yDistance);
    }

    calculateAngle(positionA : Position, positionB : Position)
    {
        let xDistance = positionA[0] - positionB[0];
        let yDistance = positionA[1] - positionB[1];

        return Math.atan2(yDistance, xDistance);
    }
}

/*
import { PtsCanvas } from 'react-pts-canvas';
import { Create } from 'pts';
import { Colors } from './Colors';

//TODO: animated background dot color should get whiter as it approaches the mouse 
export class AnimatedBackground extends PtsCanvas {

    mousePosition = [0, 0];
    settings = { simulationSpeed: 0.05, pointSpeed: 1, pointMass: 1000, numberOfPoints: 50, shockwaveSize: 3, shockwaveMargin: 6, drag: 0.025, gravity: 15, maxForce: 2000, minLineDistance: 150 };

    constructor(props) {
        super(props);
        this.props = props;
    }

    _create() {
        //get mouse movement updates
        window.onmousemove = (e) => this.mouseMove(e);

        // Create a line and a grid, and convert them to `Noise` points
        let gd = Create.gridPts(this.space.innerBound, 20, 20);
        this.noiseGrid = Create.noisePts(gd, 0.05, 0.1, 20, 20);

        const width = this.getWidth();
        const height = this.getHeight();
        const area = width * height;
        const sizeCoef = area * 0.000001;

        //fewer points and slower speed for small devices
        let numberOfPoints = Math.floor(this.settings.numberOfPoints * sizeCoef);
        this.settings.simulationSpeed *= sizeCoef;

        let pointSpeed = this.settings.pointSpeed;
        this.points = [];
        for (let i = 0; i < numberOfPoints; i++) 
        {
            let newX = width * Math.random();
            let newY = height * Math.random();

            let xSpeed = pointSpeed * (Math.random() * 2 - 1);
            let ySpeed = pointSpeed * (Math.random() * 2 - 1);

            let newColor = Colors.sunset[Math.floor(Math.random() * Colors.sunset.length)];

            let newPoint = { position: [newX, newY], xSpeed: xSpeed, ySpeed: ySpeed, color: newColor };
            this.points.push(newPoint);
        }

        this.space.background = Colors.background;
    }

    componentDidUpdate() {
        if (this.props.pause) {
            this.space.pause();
        } else {
            this.space.resume();
        }
    }


    // Override PtsCanvas' start function
    start(space, bound) {
        this._create();
    }


    // Override PtsCanvas' resize function
    resize() {
        //this._create();
    }


    // Override PtsCanvas' animate function
    animate(time, ftime) {

        if (!this.points) return;

        const width = this.getWidth();
        const height = this.getHeight();

        //move dots
        this.points.forEach(point => 
        {
            this.moveTowardsMouse(point);

            this.applyDrag(point);

            this.applyShockwave(point);

            //move point
            point.position[0] += point.xSpeed * this.settings.simulationSpeed;
            point.position[1] += point.ySpeed * this.settings.simulationSpeed;

            //wrap point at edges
            let wrapMargin = 32;

            if(point.position[0] < -wrapMargin)
                point.position[0] = width;
            else if(point.position[0] > width + wrapMargin)
                point.position[0] = 0;

            if(point.position[1] < -wrapMargin)
                point.position[1] = height;
            else if(point.position[1] > height + wrapMargin)
                point.position[1] = 0;
        });

        //draw lines
        let minimumDistance = this.settings.minLineDistance;

        //compares each point to every other point (but not to itself)
        for (let i = 0; i < this.points.length; i++) 
        {
            const pointA = this.points[i];
            
            for (let j = 0; j < this.points.length; j++)
            {
                if(i <= j) continue; //do not compare point to itself, also this check prevents pairs from being compared twice

                const pointB = this.points[j];

                let distanceBetweenPoints = this.calculateDistance(pointA.position, pointB.position);
                let lineOpacity = 1 - (distanceBetweenPoints / minimumDistance);
                lineOpacity = this.clamp(lineOpacity, 0, 1);

                if(distanceBetweenPoints < minimumDistance)
                {
                    this.form.stroke(pointA.color, lineOpacity).line([pointA.position, pointB.position]);
                }
            }
        }

        //draw dots
        this.points.forEach(point => 
        {
            this.form.fillOnly(point.color).point(point.position, 2, "circle");
        });
    }

    mouseMove(e)
    {
        this.mousePosition = [e.clientX, e.clientY + window.scrollY];
    }

    getWidth()
    {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    getHeight()
    {
        return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    }

    //pythagoras 2d distance function
    calculateDistance(positionA, positionB)
    {
        let xDistance = positionA[0] - positionB[0];
        let yDistance = positionA[1] - positionB[1];

        return Math.sqrt(xDistance * xDistance + yDistance * yDistance);
    }

    calculateAngle(positionA, positionB)
    {
        let xDistance = positionA[0] - positionB[0];
        let yDistance = positionA[1] - positionB[1];

        return Math.atan2(yDistance, xDistance);
    }

    moveTowardsMouse(point)
    {
        //F = G(m1 * m2) / (r*r)

        let pointMass = this.settings.pointMass;
        let G = this.settings.gravity;
        let mouseDistance = this.calculateDistance(point.position, this.mousePosition);
        let force = (G * pointMass * pointMass) / (mouseDistance * mouseDistance);

        //limit force
        let maxForce = this.settings.maxForce;
        if(force > maxForce)
            force = maxForce;

        //F = MA
        //F / M = A

        let acceleration = force / pointMass; //acceleration in direction of mouse

        let angle = this.calculateAngle(this.mousePosition, point.position); //direction of mouse

        let accelX = acceleration * Math.cos(angle);
        let accelY = acceleration * Math.sin(angle);

        point.xSpeed += accelX;
        point.ySpeed += accelY;
    }

    applyDrag(point)
    {
        let dragAmount = this.settings.drag;
        let dragCoef = 1 - dragAmount;
        point.xSpeed *= dragCoef;
        point.ySpeed *= dragCoef;
    }

    applyShockwave(point)
    {
        let pointSpeed = Math.sqrt(point.xSpeed * point.xSpeed + point.ySpeed * point.ySpeed);
        let mouseDistance = this.calculateDistance(point.position, this.mousePosition);
        let shockwaveMargin = this.settings.shockwaveMargin;
        let shockwaveSize = this.settings.shockwaveSize;
        if(mouseDistance < shockwaveMargin)
        {
            let randomAngle = 2 * Math.PI * Math.random();
            point.xSpeed = pointSpeed * shockwaveSize * Math.cos(randomAngle);
            point.ySpeed = pointSpeed * shockwaveSize * Math.sin(randomAngle);
        }
    }

    colorWithOpacity(colorHex, opacity)
    {
        let opacityString = opacity.toString(16);
        if(opacityString.length === 1)
            opacityString = "0" + opacityString;

        let color = colorHex + opacityString;
        return color;
    }

    clamp(number, min, max)
    {
        return Math.min(Math.max(number, min), max);
    }
}
*/