import React, { CSSProperties } from 'react';
import './App.css';
import { Colors } from './Colors';
import { Styles } from './Styles';
import { SectionHeading } from './SectionHeading';
import { Card, Grid, Button } from '@material-ui/core';
import { Project } from './Project';
import { Util } from './Util';

interface ProjectFocusViewProps
{
    project : Project | null
}

export class ProjectFocusView extends React.Component<ProjectFocusViewProps>
{
    render()
    {
        let isMobile = Util.isMobileDevice();

        let containerStyle : CSSProperties = 
        {
            width: "100%",
            display: this.props.project === null ? "none" : "block"
        };

        let cardStyle : CSSProperties = 
        {
            background: Colors.fill,
            color: Colors.white,
            margin: isMobile ? "16px" : "32px"
        };

        let cardHeadingStyle = Styles.cardHeadingStyle;
        cardHeadingStyle.display = "inline-block";

        let textContainerStyle : CSSProperties = 
        {
            padding: "16px",
        };

        let buttonStyle : CSSProperties = 
        {
            color: Colors.white,
            margin: "8px",
            float: "right"
        };

        return (
            <div style={containerStyle}>
                <SectionHeading title={this.props.project?.name ?? ""}/>
                <Card style={cardStyle}>
                    <div style={textContainerStyle}>
                        <h1 style={Styles.cardHeadingStyle}>{this.props.project?.name ?? ""}</h1>
                        <p style={Styles.textStyle}>{this.props.project?.description ?? ""}</p>
                    </div>
                    <div>
                        {this.getContent()}
                    </div>
                    <Button style={buttonStyle} href="#projects">Back to projects</Button>
                </Card>
            </div>
        );
    }

    getContent()
    {
        if(this.props.project === null) return "";

        let youtubeStyle : CSSProperties = 
        {
            display: "inline-block",
            border: "none",
            margin: "16px"
        };

        return (
            <div>
                <iframe style={youtubeStyle} id="ytplayer1" src="https://www.youtube.com/embed/cEhYXsBA040" allowFullScreen width="calc(100% - 32px);" height="200"></iframe>
                <iframe style={youtubeStyle} id="ytplayer2" src="https://www.youtube.com/embed/eKSmCtO8iU4" allowFullScreen width="calc(100% - 32px);" height="200"></iframe>
                <iframe style={youtubeStyle} id="ytplayer3" src="https://www.youtube.com/embed/f2kSPy2CMKo" allowFullScreen width="calc(100% - 32px);" height="200"></iframe>
                <iframe style={youtubeStyle} id="ytplayer4" src="https://www.youtube.com/embed/S-POE4jQCUM" allowFullScreen width="calc(100% - 32px);" height="200"></iframe>
                <iframe style={youtubeStyle} id="ytplayer5" src="https://www.youtube.com/embed/50rFsSqkISw" allowFullScreen width="calc(100% - 32px);" height="200"></iframe>
                <iframe style={youtubeStyle} id="ytplayer6" src="https://www.youtube.com/embed/YEGbA9cWLTQ" allowFullScreen width="calc(100% - 32px);" height="200"></iframe>
                <iframe style={youtubeStyle} id="ytplayer7" src="https://www.youtube.com/embed/eUKFK7P5RI0" allowFullScreen width="calc(100% - 32px);" height="200"></iframe>
            </div>
        )
    }
}