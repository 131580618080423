import React, { CSSProperties } from 'react';
import './App.css';
import { ProjectsGrid } from './ProjectsGrid';
import { ProjectsHeading } from './ProjectsHeading';
import { Coms } from './Coms';
import { Project } from './Project';
import { State } from './App';
import { Card, Button } from '@material-ui/core';
import { Colors } from './Colors';
import { CategorySetting } from './CategorySetting';

interface ProjectsContainerProps
{
    projectsLoadState : "loading" | "success" | "error",
    projects : Project[],
    categorySettings : CategorySetting[]
}

export class ProjectsContainer extends React.Component<ProjectsContainerProps>
{
    componentDidMount()
    {
        State.loadProjects();
    }

    render()
    {
        let containerStyle : CSSProperties = 
        {
            width: "100vw",
            boxSizing: "border-box",
            display: "table-cell",
        };

        return (
            <div>
                <div style={containerStyle}>
                    <ProjectsHeading projects={this.props.projects} categorySettings={this.props.categorySettings}/>
                    {this.getContent()}
                </div>
            </div>
        )
    }

    getContent()
    {
        let warningCardStyle : CSSProperties = 
        {
            background: Colors.fill,
            color: Colors.lightGrey,
            margin: "32px",
            display: "inline-block"
        };

        let warningInnerStyle : CSSProperties = 
        {
            padding: "16px"
        };

        let buttonStyle : CSSProperties = 
        {
            color: Colors.white,
            margin: "8px",
            float: "right"
        };

        switch(this.props.projectsLoadState)
        {
            case "error":
                return <Card style={warningCardStyle}><div style={warningInnerStyle}>Could not load projects</div><Button style={buttonStyle} onClick={() => State.loadProjects()}>Retry</Button></Card>;
            case "loading":
                return <Card style={warningCardStyle}><div style={warningInnerStyle}>Loading projects...</div></Card>;
            case "success":
                return <ProjectsGrid projects={this.props.projects} categorySettings={this.props.categorySettings}/>;
        }
    }
}