import React, { CSSProperties } from 'react';
import './App.css';
import { Project } from './Project';
import { Coms } from './Coms';
import { State } from './App';
import { Colors } from './Colors';
import { Styles } from './Styles';
import { Card, Button, Grid } from '@material-ui/core';
import { Util } from './Util';
import { CategorySetting } from './CategorySetting';

interface Link
{
    name : string,
    href : string

}

interface ProjectsGridProps
{
    projects : Project[],
    categorySettings : CategorySetting[]
}

export class ProjectsGrid extends React.Component<ProjectsGridProps>
{
    render()
    {
        let isMobile = Util.isMobileDevice();
        let projectContainerWidthPercent = isMobile ? "100%" : "25%";
        let projectContainerMarginPixels = 16;

        let containerPaddingTop = isMobile ? projectContainerMarginPixels : projectContainerMarginPixels + 8;

        let containerStyle : CSSProperties = 
        {
            padding: isMobile ? 0 : Util.cssPixelsToString(projectContainerMarginPixels),
            paddingTop: Util.cssPixelsToString(containerPaddingTop)
        };

        let projectContainerStyle : CSSProperties = 
        {
            color: Colors.white,
            background: Colors.fill,
            margin: Util.cssPixelsToString(projectContainerMarginPixels),
            marginTop: isMobile ? 0 : Util.cssPixelsToString(projectContainerMarginPixels),
            display: "flex",
            flexDirection: "column",
            width: Util.cssCalcSubtract(projectContainerWidthPercent, Util.cssPixelsToString(projectContainerMarginPixels * 2)),
            minHeight: "100%",
        };

        let projectInnerContainerStyle : CSSProperties =
        {
            padding: "12px 16px 16px 16px",
        };

        let projectImageStyle : CSSProperties = 
        {
            width: "100%",
            display: "block"
        };

        let leftButtonStyle : CSSProperties = 
        {
            color: Colors.white,
            float: "left",
            margin: "8px",
        };

        let centerButtonStyle : CSSProperties = 
        {
            color: Colors.white,
            margin: "8px",
        };

        let rightButtonStyle : CSSProperties = 
        {
            color: Colors.white,
            float: "right",
            margin: "8px",
        };

        let categoryStyle = (category : string) => 
        {
            let style : CSSProperties = {
                background: Colors.getCategoryColor(category),
                padding: "4px 16px",
                fontFamily: Styles.textFont,
                textAlign: "left"
            };

            return style;
        }

        let inDevelopmentStyle : CSSProperties = 
        {
            background: Colors.background,
            padding: "4px 16px",
            fontFamily: Styles.textFont,
            textAlign: 'left'
        };

        let inDevelopmentStyleHidden = Util.copy(inDevelopmentStyle);
        inDevelopmentStyleHidden.display = "none";

        let subheadingStyle = Styles.textStyle;
        subheadingStyle.marginBottom = "8px";

        let projectFooterStyle : CSSProperties = 
        {
            marginTop: "auto",
            textAlign: "center"
        };

        return (
            <div style={containerStyle}>
                <Grid container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch">
                    {this.getActiveProjects().map((project, key) => 
                        <Card style={projectContainerStyle} key={key}>
                            <img style={projectImageStyle} src={Coms.projectImageUrl(project.thumbnail)} />
                            <div style={projectInnerContainerStyle}>
                                <h1 style={Styles.cardHeadingStyle}>{project.name}</h1>
                                <p style={subheadingStyle}>{project.summary}</p>
                                <p style={Styles.textStyle}>{project.description}</p>
                            </div>
                            <div style={projectFooterStyle}>
                                <div style={project.inDevelopment ? inDevelopmentStyle : inDevelopmentStyleHidden}>In Development</div>
                                <div style={categoryStyle(project.category)}>{project.category}</div>
                                {this.getProjectLinks(project).map((link, key) => 
                                    <Button key={key} style={!isMobile ? rightButtonStyle : key === 1 ? centerButtonStyle : key > 0 ? leftButtonStyle : rightButtonStyle} href={Coms.projectUrl(link.href)} target={link.href.startsWith("?") ? "_self" : "_blank"} onClick={() => this.showFocusProject(project.link)}>{link.name.replace("-", " ")}</Button>
                                )}
                            </div>
                        </Card>
                    )}
                </Grid>
            </div>
        )
    }
    
    getActiveProjects()
    {
        let activeProjects : Project[] = [];
        this.props.projects.forEach(project => {
            if(Util.categoryIsEnabled(this.props.categorySettings, project.category))
                activeProjects.push(project);
        });

        return activeProjects;
    }

    showFocusProject(projectLink : string)
    {
        if(!projectLink.startsWith("?")) return;

        State.setFocusProject(projectLink);
    }

    getProjectLinks(project : Project) : Link[]
    {
        try
        {
            let linkObject = JSON.parse(project.link);
            console.log(linkObject)

            let output : Link[] = [];
            for (const property in linkObject) 
            {
                let href = linkObject[property];
                let name = property;
                console.log(`${name}: ${href}`);
                let linkObj : Link = {
                    name: name,
                    href: href
                };

                output.push(linkObj);
            }

            return output;
        }
        catch(error)
        {
            let viewButton : Link = { name: "View", href: project.link };
            return [ viewButton ];
        }
    }

}