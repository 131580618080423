import React, { CSSProperties, createRef } from 'react';
import './App.css';
import { Colors } from './Colors';
import { Styles } from './Styles';
import { ViewWorkButton } from './ViewWorkButton';
import { SectionHeading } from './SectionHeading';
import { Card, Grid, Button } from '@material-ui/core';
import { SkillHolder } from './SkillHolder';
import { Util } from './Util';
import { stringify } from 'querystring';
import { Coms } from './Coms';

interface ContactMeProps { }

interface ContactMeState 
{
    messageSent : boolean,
    nameError : boolean,
    emailError : boolean,
    subjectError : boolean,
    messageError : boolean
}

export class ContactMe extends React.Component<ContactMeProps, ContactMeState>
{
    state = { messageSent: false, nameError: false, emailError: false, subjectError: false, messageError: false };

    inputName = createRef<HTMLInputElement>();
    inputEmail = createRef<HTMLInputElement>();
    inputSubject = createRef<HTMLInputElement>();
    inputMessage = createRef<HTMLTextAreaElement>();

    render()
    {
        let isMobile = Util.isMobileDevice();

        let containerStyle : CSSProperties = 
        {
            width: "100%",
        };

        let contentContainerStyle : CSSProperties = 
        {
            textAlign: "center"
        };

        let cardMargin = isMobile ? 16 : 32;
        let cardStyle : CSSProperties = 
        {
            textAlign: "left",
            background: Colors.fill,
            color: Colors.white,
            display: "inline-block",
            width: isMobile ? Util.cssCalcSubtract("100%", cardMargin * 2 + "px") : "50%",
            minWidth: "300px",
            minHeight: "200px",
            margin: cardMargin + "px"
        };

        let cardContentStyle : CSSProperties =
        {
            padding: "16px 16px 0 16px" 
        }

        let inputStyle : CSSProperties = 
        {
            color: Colors.white,
            background: Colors.background,
            border: "1px solid",
            borderColor: Colors.background,
            borderRadius: 4,
            boxSizing: "border-box",
            width: "100%",
            padding: "12px",
            marginTop: "16px",
            fontSize: "16px"
        };

        //get text style for input boxes
        inputStyle = Object.assign(Styles.textStyle, inputStyle);
        let inputErrorStyle = Util.copy(inputStyle);
        inputErrorStyle.borderColor = Colors.red;

        let textAreaStyle = Util.copy(inputStyle);
        textAreaStyle.minHeight = "200px";
        let textAreaErrorStyle = Util.copy(textAreaStyle);
        textAreaErrorStyle.borderColor = Colors.red;
        
        let buttonStyle : CSSProperties = 
        {
            color: Colors.white,
            margin: "8px",
            float: "right"
        };

        let invisibleStyle : CSSProperties = 
        {
            visibility: "hidden"
        };

        let contactFormCard = 
        <Card style={cardStyle}>
            <div style={cardContentStyle}>
                <h1 style={Styles.cardHeadingStyle}>Get in touch</h1>
                <p style={Styles.textStyle}>Let's work together!</p>
                <form>
                    <input ref={this.inputName} type="text" style={this.state.nameError ? inputErrorStyle : inputStyle} placeholder="Name" onChange={() => this.inputChange("name")}></input>
                    <input ref={this.inputEmail} type="text" style={this.state.emailError ? inputErrorStyle : inputStyle} placeholder="Email address" onChange={() => this.inputChange("email")}></input>
                    <input ref={this.inputSubject} type="text" style={this.state.subjectError ? inputErrorStyle : inputStyle} placeholder="Subject" onChange={() => this.inputChange("subject")}></input>
                    <textarea ref={this.inputMessage} name="message" placeholder="Message" style={this.state.messageError ? textAreaErrorStyle : textAreaStyle} onChange={() => this.inputChange("message")}></textarea>
                </form>
            </div>
            <Button style={buttonStyle} onClick={() => this.submitForm()}>Send</Button>
        </Card>;

        let messageSentCard =
        <Card style={cardStyle}>
            <div style={cardContentStyle}>
                <h1 style={Styles.cardHeadingStyle}>Message sent</h1>
                <p style={Styles.textStyle}>Thanks for getting in touch!</p>
                <form style={invisibleStyle}>
                    <input type="text" style={this.state.nameError ? inputErrorStyle : inputStyle} placeholder="Name" onChange={() => this.inputChange("name")}></input>
                    <input type="text" style={this.state.emailError ? inputErrorStyle : inputStyle} placeholder="Email address" onChange={() => this.inputChange("email")}></input>
                    <input type="text" style={this.state.subjectError ? inputErrorStyle : inputStyle} placeholder="Subject" onChange={() => this.inputChange("subject")}></input>
                    <textarea name="message" placeholder="Message" style={this.state.messageError ? textAreaErrorStyle : textAreaStyle} onChange={() => this.inputChange("message")}></textarea>
                    <Button style={buttonStyle} onClick={() => this.submitForm()}>Send</Button>
                </form>
            </div>
        </Card>;

        return (
            <div style={containerStyle}>
                <SectionHeading title={"Contact Me"}/>
                <div style={contentContainerStyle}>
                    {this.state.messageSent ? messageSentCard : contactFormCard}
                </div>
            </div>
        )
    }

    inputChange(field : string)
    {
        switch(field)
        {
            case "name":
                this.setState({ nameError : false });
                break;
            case "email":
                this.setState({ emailError : false });
                break;
            case "subject":
                this.setState({ subjectError : false });
                break;
            case "message":
                this.setState({ messageError : false });
                break;
        }
    }

    submitForm()
    {
        if(!this.inputName.current) return;
        if(!this.inputEmail.current) return;
        if(!this.inputSubject.current) return;
        if(!this.inputMessage.current) return;

        let name = this.inputName.current.value;
        let email = this.inputEmail.current.value;
        let subject = this.inputSubject.current.value;
        let message = this.inputMessage.current.value;

        this.setState({ nameError: false, emailError: false, subjectError: false, messageError: false });
        if(!name)
        {
            this.setState({ nameError: true });
            return;
        } 
        
        if(!email || !Util.validateEmail(email))
        {
            this.setState({ emailError: true });
            return;
        } 
        
        if(!subject)
        {
            this.setState({ subjectError: true });
            return;
        } 
        
        if(!message)
        {
            this.setState({ messageError: true });
            return;
        }

        let formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("subject", subject);
        formData.append("message", message);

        let request = Coms.contactRequest();
        request.onload = () =>
        {
            try
            {
                let response = JSON.parse(request.response);
                if(!response.success)
                {
                    alert("Message could not be sent. An error occurred:\n" + response.error);
                    return;
                }

                this.setState({ messageSent: true });
            }
            catch
            {
                alert("Message could not be sent. An unexpected error occurred");
            }
        };

        request.send(formData);
    }
}