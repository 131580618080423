export class Colors
{
    static background = "#303336";
    static border = "#666";
    static fill = "#3D4043";
    static white = "#fff";
    static transparent = "transparent";
    static lightGrey = "#eeeeee";
    static midGrey = "#B2B2B2";
    static red = "#ec2f42";
    static sunset = ["#eeaf61", "#fb9062", "#ee5d6c", "#ce4993", "#6a0d83"];

    static getCategoryColor(category : string)
    {
        switch(category)
        {
            case "Game":
            case "Mobile":
                return Colors.sunset[1];

            case "Application":
            case "Web":
                return Colors.sunset[2];

            case "Music":
            case "Other":
                return Colors.sunset[3];
            
            default:
                return Colors.white;
        }
    }
}